import React, {useMemo, useRef} from 'react'
import WizImage from '@component/images/WizImage'
import UserThumbnail from '@component/images/UserThumbnail'
import Text from '@component/Text/Text'
import MobileSidebarModal from '@feature/portfolio/components/MobileSidebarModal/MobileSidebarModal'
import IconMore from '@svg/common/ic_more.svg'
import useSnackbar from '@hook/useSnackbar'
import usePortfolio from '@hook/usePortfolio'
import useLogin from '@hook/useLogin'
import {useLoginModalStore} from '@store/LoginModalStore'
import CreateCopyModal from '@feature/copy/components/CreateCopyModal/CreateCopyModal'
import FollowModal from '@feature/portfolio/components/FollowModal/FollowModal'
import {useTranslation} from 'next-i18next'
import useQueryGetUserSidebar from '@hook/query/portfolio/useQueryGetUserSidebar'
import useWindowSize from '@hook/useWindowSize'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import {useQueryClient} from '@tanstack/react-query'
import {createLog} from '@util/logs'
import {useRouter} from 'next/router'
import IconPrivate from '@svg/portfolio/ic_private_portfolio.svg'
import {UserPrivacy} from '@type/profile'
import SkeletonBlock from '@component/skeleton/SkeletonBlock'

interface IProps {
    className?: string
    id: string
    isMe?: boolean
}

const MobileSideBarContainer: React.FC<IProps> = ({className, id, isMe}) => {
    const {t} = useTranslation()
    const {showSnackbar} = useSnackbar()
    const router = useRouter()
    const {isLg, isSm} = useWindowSize()

    const queryClient = useQueryClient()

    const {followUser} = usePortfolio()
    const showLoginModal = useLoginModalStore(state => state.showLoginModal)

    const {isLogin} = useLogin(id)
    const {data: sidebar, refetch: refreshSidebar, isLoading, isFetching, isSuccess} = useQueryGetUserSidebar(id, isLg)

    const sidebarModalRef = useRef<any>()
    const createCopyModalRef = useRef<any>()
    const followModalRef = useRef<any>()

    const onRefresh = async () => {
        await refreshSidebar()
        await queryClient.refetchQueries(QueryCacheKeys.portfolio.getUserProfile(id, router?.locale))
    }

    const onClickOpenFollowModal = async (e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
        e.stopPropagation()

        if (!isLogin()) {
            showLoginModal()
            return
        }

        if (sidebar?.is_following === false) {
            await followUser(sidebar?.user_id, onRefresh)
            return
        }

        createLog('event', 'portfolio_sidebar_open_follow_modal', {user_id: id})
        followModalRef.current.show()
    }
    const onClickOpenSidebarModal = () => {
        createLog('event', 'portfolio_sidebar_open_sidebar_modal')
        sidebarModalRef?.current?.show()
    }

    const isRenderPrivacyIcon = useMemo(() => {
        if (sidebar?.privacy_level === UserPrivacy.OpenToSubscriber && !sidebar?.is_following) {
            return true
        }
        if (
            sidebar?.privacy_level === UserPrivacy.Private ||
            sidebar?.privacy_level === UserPrivacy.CompletelyPrivate
        ) {
            return true
        }
        return false
    }, [sidebar?.privacy_level, sidebar?.is_following])

    return (
        <div
            onClick={onClickOpenSidebarModal}
            className={
                'lg:col-span-2 hidden lg:flex lg:justify-around bg-transparent border-b border-b-transparent02 dark:border-b-bg_dark_white01 w-full p-[10px] space-x-[10px] min-h-[55px] items-center cursor-pointer'
            }>
            {isLoading && isFetching && !isSuccess ? (
                <SkeletonBlock className={'w-[28px] h-[28px]'} sizeClassName={'h-full'} />
            ) : (
                <UserThumbnail src={sidebar?.thumbnail} size={28} isPro={sidebar?.is_pro} />
            )}
            <div className={'grow'}>
                {isLoading && isFetching && !isSuccess ? (
                    <SkeletonBlock className={'w-[150px] h-[24px]'} sizeClassName={'h-[16px]'} />
                ) : (
                    <div className={'flex space-x-[5px] items-center'}>
                        {!isMe && isRenderPrivacyIcon && (
                            <IconPrivate className={'w-[16px] fill-gray01 dark:fill-dark_gray01 flex-none'} />
                        )}
                        <Text
                            inlineStyle={{
                                fontSize: isSm && sidebar?.username?.length > 15 ? 'max(2.67cqi, 10px)' : '16px',
                            }}
                            className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                            {sidebar?.username}
                        </Text>
                        {sidebar?.flags?.length > 0 && (
                            <WizImage
                                src={sidebar?.flags[0]}
                                width={18}
                                height={18}
                                containerClassName={'rounded-full overflow-hidden'}
                            />
                        )}
                    </div>
                )}
                {isLoading && isFetching && !isSuccess ? (
                    <SkeletonBlock className={'w-[100px] h-[18px] lg:hidden'} sizeClassName={'h-[12px]'} />
                ) : (
                    !isMe && (
                        <div className={'flex space-x-[5px] items-center'}>
                            <Text
                                className={'text-ti3 text-gray02 dark:text-dark_gray02 cursor-pointer'}
                                onClick={onClickOpenFollowModal}>
                                {sidebar?.is_following
                                    ? t('portfolio.sidebar.unsubscribe.title')
                                    : t('portfolio.sidebar.subscribe.title')}
                            </Text>
                            <Text className={'text-ti3 text-gray02 dark:text-dark_gray02'}>{'•'}</Text>
                            <Text
                                className={`text-ti3  ${
                                    sidebar?.can_copy === true
                                        ? 'text-gray02 dark:text-dark_gray02 cursor-pointer'
                                        : 'text-gray04 dark:text-dark_gray04 cursor-default'
                                }`}
                                onClick={e => {
                                    e.stopPropagation()
                                    if (sidebar?.can_copy === false) {
                                        showSnackbar(t('portfolio.sidebar.copy.unavailable'))
                                        return
                                    }

                                    if (!isLogin()) {
                                        showLoginModal()
                                        return
                                    }
                                    createLog('event', 'portfolio_sidebar_open_copy_modal')
                                    createCopyModalRef.current.show()
                                }}>
                                {t('portfolio.sidebar.copy.title')}
                            </Text>
                        </div>
                    )
                )}
            </div>
            <IconMore className={'w-[24px] fill-gray01 dark:fill-dark_gray01'} />
            <MobileSidebarModal ref={sidebarModalRef} sidebar={sidebar} />
            <CreateCopyModal ref={createCopyModalRef} leaderUserId={id} />
            <FollowModal
                ref={followModalRef}
                user_id={id}
                isFollowing={sidebar?.is_following}
                refreshCallback={onRefresh}
            />
        </div>
    )
}
export default React.memo(MobileSideBarContainer)
