import React, {useImperativeHandle, useMemo, useRef} from 'react'
import WizContentModal from '@component/modal/WizContentModal'
import {useTranslation} from 'next-i18next'
import UserThumbnail from '@component/images/UserThumbnail'
import Text from '@component/Text/Text'
import WizImage from '@component/images/WizImage'
import SidebarBalanceChart from '@feature/portfolio/components/charts/SidebarBalanceChart/SidebarBalanceChart'
import {getNumberColorClass} from '@util/etc'
import SidebarProfitChart from '@feature/portfolio/components/charts/SidebarProfitChart/SidebarProfitChart'
import Link from 'next/link'
import IconDollar from '@svg/portfolio/ic_sidebar_dollar.svg'
import IconLeaderEarning from '@svg/portfolio/ic_sidebar_leader_earning.svg'
import IconWalletBalance from '@svg/portfolio/ic_sidebar_wallet.svg'
import IconInvoices from '@svg/portfolio/ic_sidebar_invoice.svg'
import NewIcon from '@svg/common/ic_new.svg'
import {ISidebar} from '@api/user/sidebar/getSidebar'
import useFormatNumber from '@hook/useFormatNumber'
import useFormatDate from '@hook/useFormatDate'
import useNavigation from '@hook/useNavigation'
import SharePortfolioModal from '@feature/portfolio/components/SharePortfolioModal/SharePortfolioModal'
import NotRegisteredApiModal from '@feature/portfolio/components/NotRegisteredApiModal/NotRegisteredApiModal'
import {useRouter} from 'next/router'
import useCopy from '@hook/useCopy'
import useSnackbar from '@hook/useSnackbar'
import SharePortfolioButton from '@feature/portfolio/components/SharePortfolioButton/SharePortfolioButton'
import usePortfolioPriavcy from '@hook/usePortfolioPrivacy'
import useQueryGetUserGraph from '@hook/query/portfolio/useQueryGetUserGraph'
import {DayDuration} from '@type/time'
import useQueryGetPendingInvoices from '@hook/query/user/account/invoices/useQueryGetPendingInvoices'
import useLogin from '@hook/useLogin'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import IconLink from '@svg/common/ic_copy_link.svg'
import WizIcon from '@component/images/WizIcon'
import IconDollarDark from '@svg/portfolio/ic_sidebar_dollar_dark.svg'
import useDark from '@hook/useDark'
import IconLeaderEarningDark from '@svg/portfolio/ic_sidebar_leader_earning_dark.svg'
import IconWalletBalanceDark from '@svg/portfolio/ic_sidebar_wallet_dark.svg'
import IconInvoicesDark from '@svg/portfolio/ic_sidebar_invoice_dark.svg'
import {createLog} from '@util/logs'
import {
    getLinkUrl,
    URL_ACCOUNT_INVOICE,
    URL_ACCOUNT_WALLET,
    URL_COPY_PORTFOLIO,
    URL_LEADER_PORTFOLIO,
} from '@constant/url'
import {UserPrivacy} from '@type/profile'
import IconPrivate from '@svg/portfolio/ic_private_portfolio.svg'

interface IProps {
    className?: string
    sidebar: ISidebar
}

const MobileSidebarModal = ({className, sidebar}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const shareModalRef = useRef<any>()
    const notRegisteredApiModalRef = useRef<any>()
    const {t} = useTranslation()
    const {displayNumber, displayBalance, displayPercent} = useFormatNumber()
    const {displayDateFromText} = useFormatDate()
    const {goSubscriber} = useNavigation()
    const router = useRouter()
    const {copyToClipboard} = useCopy()
    const {showSnackbar} = useSnackbar()
    const {isRenderDark} = useDark()
    useImperativeHandle(ref, () => ({
        show: () => {
            modalRef.current.show()
        },
    }))

    const {data: pendingInvoices} = useQueryGetPendingInvoices(false)
    const {chartGraphData} = useQueryGetUserGraph(sidebar?.user_id, DayDuration.Lifetime, false)
    const {isHidePortfolio} = usePortfolioPriavcy(sidebar?.privacy_level, sidebar?.is_following, sidebar?.user_id)
    const {data: me} = useQueryFetchMe(undefined, false)
    const {isMe} = useLogin(sidebar?.user_id)

    const onClickOpenSharePortfolioModal = () => {
        createLog('event', 'portfolio_sidebar_modal_open_share_portfolio_modal')
        if (!me?.keys || me?.keys?.length < 1) {
            notRegisteredApiModalRef?.current?.show()
            return
        }
        shareModalRef?.current?.show()
    }

    const isRenderPrivacyIcon = useMemo(() => {
        if (sidebar?.privacy_level === UserPrivacy.OpenToSubscriber && !sidebar?.is_following) {
            return true
        }
        if (
            sidebar?.privacy_level === UserPrivacy.Private ||
            sidebar?.privacy_level === UserPrivacy.CompletelyPrivate
        ) {
            return true
        }
        return false
    }, [sidebar?.privacy_level, sidebar?.is_following])

    return (
        <>
            <WizContentModal size={'400'} ref={modalRef}>
                <div className={`${className}`}>
                    <div className={'w-full'}>
                        <div className={'flex flex-col items-center'}>
                            <UserThumbnail src={sidebar?.thumbnail} size={50} containerClassName={'w-[50px]'} />
                            <div className={'flex space-x-[5px] items-center mt-[10px]'}>
                                {!isMe && isRenderPrivacyIcon && (
                                    <IconPrivate className={'w-[16px] fill-gray01 dark:fill-dark_gray01 flex-none'} />
                                )}
                                <Text className={'text-ti1 text-center break-all text-gray01 dark:text-dark_gray01'}>
                                    {sidebar?.username}
                                </Text>
                                {sidebar?.flags?.length > 0 && (
                                    <WizImage
                                        src={sidebar?.flags[0]}
                                        width={18}
                                        height={18}
                                        containerClassName={'rounded-full overflow-hidden'}
                                    />
                                )}
                                <WizIcon
                                    onClick={() => {
                                        createLog('event', 'portfolio_sidebar_modal_copy_share_link')
                                        copyToClipboard(
                                            `${window.location.origin}/${router.locale}/user/${sidebar?.user_id}/portfolio`,
                                        )
                                        showSnackbar(t('common.copied'))
                                    }}
                                    backgroundColorClassName={
                                        'hover:bg-transparent01 dark:bg-dark_gray07 dark:hover:bg-dark_gray06 p-[5px] cursor-pointer fill-gray02 dark:fill-dark_gray02 hover:fill-gray01 dark:hover:fill-dark_gray01 '
                                    }>
                                    <IconLink className={'w-[14px]'} />
                                </WizIcon>
                            </div>
                            {isMe && (
                                <SharePortfolioButton
                                    text={t('portfolio.sidebar.share.portfolio')}
                                    onClick={onClickOpenSharePortfolioModal}
                                />
                            )}
                            <div className={'flex space-x-[10px] mt-[10px]'}>
                                <button
                                    className={'text-center'}
                                    onClick={() => {
                                        createLog('event', 'portfolio_sidebar_modal_click_following')
                                        goSubscriber('following')
                                    }}>
                                    <Text className={'text-body_comm text-gray03 dark:text-dark_gray03'}>
                                        {t('portfolio.sidebar.subscribing.title')}
                                    </Text>
                                    <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                                        {displayNumber(sidebar?.following)}
                                    </Text>
                                </button>
                                <button
                                    className={'text-center'}
                                    onClick={() => {
                                        createLog('event', 'portfolio_sidebar_modal_click_followers')
                                        goSubscriber('followers')
                                    }}>
                                    <Text className={'text-body_comm text-gray03 dark:text-dark_gray03'}>
                                        {t('portfolio.sidebar.subscribers.title')}
                                    </Text>
                                    <Text className={'text-ti1 text-gray01 dark:text-dark_gray01'}>
                                        {displayNumber(sidebar?.followers)}
                                    </Text>
                                </button>
                            </div>

                            <Text
                                className={'mt-[10px] text-body2 text-gray02 dark:text-dark_gray02 break-all'}
                                enableHyperlink={true}>
                                {sidebar?.status}
                            </Text>

                            <div className={'bg-white dark:bg-bg_dark_white01 w-full mt-[20px] p-[15px]'}>
                                <Text className={'text-ti4 text-gray03 dark:text-dark_gray03 text-center'}>
                                    {t('portfolio.sidebar.summary.portfolioBalance')}
                                </Text>
                                <Text className={'text-ti2 text-gray01 dark:text-dark_gray01 text-center'}>
                                    {displayBalance(sidebar?.current_balance ?? 0, {
                                        showPreSign: true,
                                        isApproximately: isHidePortfolio,
                                    })}
                                </Text>

                                <SidebarBalanceChart height={50} input={chartGraphData} />

                                <Text className={'text-ti4 text-gray03 dark:text-dark_gray03 text-center mt-[30px] '}>
                                    {t('portfolio.sidebar.summary.allTimeProfit')}
                                </Text>
                                <Text
                                    className={`text-ti2 text-center ${getNumberColorClass(
                                        sidebar?.all_time_profit_rate,
                                    )}`}>
                                    {displayPercent(sidebar?.all_time_profit_rate ?? 0)}
                                </Text>

                                <SidebarProfitChart height={50} input={chartGraphData} />

                                {isMe && (
                                    <>
                                        <Link
                                            href={getLinkUrl(URL_COPY_PORTFOLIO(sidebar?.user_id))}
                                            onClick={() =>
                                                createLog('event', 'portfolio_sidebar_modal_click_copy_earning')
                                            }
                                            className={
                                                'flex items-center hover:bg-gray07 dark:hover:bg-dark_gray07 rounded-md cursor-pointer mt-[20px] py-2'
                                            }>
                                            <div className={'flex space-x-[10px] items-center'}>
                                                {isRenderDark ? <IconDollarDark /> : <IconDollar />}
                                                <div>
                                                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03'}>
                                                        {t('portfolio.sidebar.summary.copyEarning')}
                                                    </Text>
                                                    <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                                        {displayBalance(sidebar?.copy_earning, {showPreSign: true})}
                                                    </Text>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link
                                            href={getLinkUrl(URL_LEADER_PORTFOLIO(sidebar?.user_id))}
                                            onClick={() =>
                                                createLog('event', 'portfolio_sidebar_modal_click_leader_earning')
                                            }
                                            className={
                                                'flex items-center hover:bg-gray07 dark:hover:bg-dark_gray07 rounded-md cursor-pointer mt-2 py-2'
                                            }>
                                            <div className={'flex space-x-[10px] items-center'}>
                                                {isRenderDark ? <IconLeaderEarningDark /> : <IconLeaderEarning />}
                                                <div>
                                                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03'}>
                                                        {t('portfolio.sidebar.summary.leaderEarning')}
                                                    </Text>
                                                    <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                                        {displayBalance(me?.leader_earning, {
                                                            showPreSign: true,
                                                        })}
                                                    </Text>
                                                </div>
                                            </div>
                                        </Link>

                                        <Link
                                            href={getLinkUrl(URL_ACCOUNT_WALLET)}
                                            onClick={() =>
                                                createLog('event', 'portfolio_sidebar_modal_click_total_value')
                                            }
                                            className={
                                                'flex items-center hover:bg-gray07 dark:hover:bg-dark_gray07 rounded-md cursor-pointer mt-2 py-2'
                                            }>
                                            <div className={'flex space-x-[10px] items-center'}>
                                                {isRenderDark ? <IconWalletBalanceDark /> : <IconWalletBalance />}
                                                <div>
                                                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03'}>
                                                        {t('portfolio.sidebar.summary.walletBalance')}
                                                    </Text>
                                                    <Text className={'text-ti2 text-gray01 dark:text-dark_gray01'}>
                                                        {displayBalance(me?.wallets?.total_value, {
                                                            showPreSign: true,
                                                        })}
                                                    </Text>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link
                                            href={getLinkUrl(URL_ACCOUNT_INVOICE)}
                                            onClick={() =>
                                                createLog('event', 'portfolio_sidebar_modal_click_pending_invoice')
                                            }
                                            className={
                                                'flex items-center hover:bg-gray07 dark:hover:bg-dark_gray07 rounded-md cursor-pointer mt-2 py-2'
                                            }>
                                            <div className={'flex space-x-[10px] items-center'}>
                                                {isRenderDark ? <IconInvoicesDark /> : <IconInvoices />}
                                                <div>
                                                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03'}>
                                                        {t('portfolio.sidebar.summary.invoice')}
                                                    </Text>
                                                    <div className={'flex gap-x-[5px] items-center'}>
                                                        <Text
                                                            className={
                                                                'text-ti2 text-gray01 dark:text-dark_gray01 underline'
                                                            }>
                                                            {displayNumber(pendingInvoices?.length)}
                                                        </Text>
                                                        {Number(pendingInvoices?.length) > 0 && (
                                                            <NewIcon className={'fill-red dark:fill-dark_red'} />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </>
                                )}
                            </div>

                            <div className={'bg-white dark:bg-bg_dark_white01 w-full mt-[20px] p-[15px]'}>
                                <div className={'flex justify-between'}>
                                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03 text-center'}>
                                        {t('portfolio.sidebar.summary.joined')}
                                    </Text>
                                    <Text className={'text-body3 text-gray01 dark:text-dark_gray01 text-center'}>
                                        {displayDateFromText(sidebar?.joined_at, t('dateFormat.date'))}
                                    </Text>
                                </div>
                                <div className={'flex justify-between mt-[7px]'}>
                                    <Text className={'text-body3 text-gray03 dark:text-dark_gray03 text-center'}>
                                        {t('portfolio.sidebar.summary.views')}
                                    </Text>
                                    <Text className={'text-body3 text-gray01 dark:text-dark_gray01 text-center'}>
                                        {displayNumber(sidebar?.views)}
                                    </Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </WizContentModal>
            <SharePortfolioModal ref={shareModalRef} />
            <NotRegisteredApiModal ref={notRegisteredApiModalRef} />
        </>
    )
}

export default React.forwardRef(MobileSidebarModal)
